html,
body,
#root {
  margin: 0px;
  padding: 0px;
  font-family: hiyllo, sans-serif;
  height: 100%;
  overflow: auto;
  background-color: transparent;
  color: #ffffff;
  color-scheme: dark;
  overscroll-behavior-block: none;
}

div {
  outline: none;
}

.NoScrollBar::-webkit-scrollbar {
  display: none;
}

.NoScrollBar {
  -ms-overflow-style: none;
  scroll-behavior: none;
}

.HoverHideParent:not(:hover) {
  justify-content: center;
}

.HoverHideParent:hover {
  justify-content: space-between;
}

.HoverHideParent > .HoverShowChild {
  display: none;
}

.HoverHideParent:hover > .HoverShowChild {
  display: unset;
}

.HoverHideParent > .HoverHideChild {
  display: unset;
}

.HoverHideParent:hover > .HoverHideChild {
  display: none;
}

.TableRowActive {
  background: #2196f340;
}

.TableColumnActive {
  background: #2196f340;
}

[contentEditable="true"]:empty:not(:focus):before {
  content: attr(placeholder);
  opacity: 0.3;
  pointer-events: none;
}

::-webkit-input-placeholder {
  color: inherit;
}

textarea {
  font-family: inherit;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  color: inherit;
}

ul {
  margin-top: 0px;
  margin-bottom: 0px;
}

p {
  margin-top: 0px;
  margin-bottom: 0px;
}

/* total width */
body::-webkit-scrollbar {
  background-color: transparent;
  width: 16px;
}

/* background of the scrollbar except button or resizer */
body::-webkit-scrollbar-track {
  background-color: transparent;
}

/* scrollbar itself */
body::-webkit-scrollbar-thumb {
  background-color: #000000;
  border-radius: 16px;
  border: 4px solid #000000;
}

/* set button(top and bottom of the scrollbar) */
body::-webkit-scrollbar-button {
  display: none;
}

::placeholder {
  opacity: 0.5;
  color: inherit;
}

.MessageTextContainer a {
  color: inherit;
}

.MessageTextContainer h1,
.MessageTextContainer h2,
.MessageTextContainer h3,
.MessageTextContainer h4,
.MessageTextContainer h5,
.MessageTextContainer h6,
.MessageTextContainer pre {
  margin-top: 0px;
  margin-bottom: 0px;
}

.MessageTextContainer code {
  background-color: #000000;
  color: #0798f2;
  padding: 5px;
  padding-top: 2.5px;
  padding-bottom: 2.5px;
  border-radius: 5px;
  overflow-x: auto;
  width: calc(100% - 100px);
}

.MessageTextContainer pre > code {
  padding: 10px;
  display: block;
}

.MessageTextContainer ul,
.MessageTextContainer ol {
  margin-top: 0px;
  margin-bottom: 0px;
  display: flex;
  flex-direction: column;
  margin-block-start: 0px;
  padding-inline-start: 15px;
  margin-block-end: 0px;
  white-space: normal;
}

.MessageTextContainer li > p {
  display: inline;
  margin: 0 !important;
  padding: 0 !important;
}

.MessageTextContainer blockquote {
  margin: 0px;
  margin-block-start: 0px;
  margin-block-end: 0px;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  display: flex;
  border-left: 2.5px white solid;
  padding-left: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.ql-editor {
  outline: none;
}

.MessageTextContainer {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.MessageTextContainer p {
  margin-block-start: 0px;
  margin-block-end: 0px;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

.ql-font-hiyllo {
  font-family: "hiyllo";
}

.no-scroll-bar::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.RenderSafe p {
  min-height: 1em;
}

.HiylloMeetTile video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
